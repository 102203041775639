import axios from 'axios';
import generateSignature from './signatureService';

const mode = 'production';

const devBaseUrl = 'https://ryr-api.test.tc8l.nl/pr2';
const prodBaseUrl = 'https://fr01-wp2-ryrv2-api-prd-as.azurewebsites.net/fr923';

const apiKey = mode === 'development' ? 'AppApiKey' : 'xTV8i8PTBlypx4MVLyHB6h6s2IBZxodXTK4IdWrPrc7OkSBlKi7qUR6RK205TETs';
const secretKey = mode === 'development' ? 'AppSecretKey' : 'Prqhyxcbc5lmGdgMQ2n4rUF8RruMAlqlxgt9toVlJLWB7o0b3DEla9cSDo3JxeoE';

const timeHttp = axios.create({baseURL: mode === 'development' ?  devBaseUrl : prodBaseUrl});
const axiosInstance = axios.create({baseURL: mode === 'development' ? devBaseUrl : prodBaseUrl});

axiosInstance.interceptors.request.use(
    async config => {
        try {
            const res = await timeHttp.get('/timestamp');
            const timeStamp = res.data.timeStamp;

            const token = localStorage.getItem('token');
            const key = generateSignature(apiKey, timeStamp, secretKey);
            
            config.headers = {
                'Content-Type': 'application/json',
                'Api-Key': apiKey,
                Signature: key, 
                Authorization: 'Bearer ' + token,
                TimeStamp: timeStamp
            };
        } catch (error) {
            console.log(error);
        }               
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

export default axiosInstance;